// Home.js
import React from 'react';

const Home = () => {
    return (


        <main>
            <img
                src="feature.jpg"
                alt="bg copy.png"
                class="image-logo"                
                width="980"
                height="402"
                fetchpriority="high"
            />
            <section >
                <div>
                    <h2 class="hero heading_hero is-services">
                        Improving the Digital Experience.
                        <span class="is-highlight_effect">Reflecting Your Vision.</span>
                    </h2>
                    <p class="main-content">
                        We're here to be a catalyst in creating digital experiences that empower all people.  We challenge
                        assumptions and identify gaps. People are multisensory,
                        neurodivergent and uniquely built. Designing and building solutions should reflect that.
                    </p>
                    <blockquote class="main-quote" >
                        “The world is full of magic things, patiently waiting for our senses
                        to grow sharper.”

                        <cite>  - W.B. Yeats</cite>
                    </blockquote>
                    {/* <div class="bar_hero"></div> */}
                </div>
            </section>
        </main>

    );
}

export default Home;