// Footer.js
import React from 'react';

const Footer = () => {
    return (
        <footer>
 
            { 

                    <div class="footer">
                        <div class="copyright">
                        <p>&copy; {new Date().getFullYear()} Sensory Reflection, LLC. All Rights Reserved.
                        </p>
                        </div>
                        <nav>
                            <ul>
                                <li><a href="#">Contact</a></li>
                            </ul>
                        </nav>
                    </div>
            }
        </footer>
    );
}

export default Footer;