// Header.js
import React from 'react';
 

const Header = () => {
    return (

            <header class="banner">
                <div>
                    <div class="title-wrapper">
                        <img src="logo.jpg" alt="Sensory Reflection Logo" class="logo" />
                        <h1 class="SRTitle">Sensory Reflection</h1>
                    </div>
                </div>
                <nav>
                    <ul>
                        <li>Home</li>
                        <li><a href="mailto:contactus@sensoryreflection.com?subject=Contact%20Sensory%20Reflection">Contact</a></li>
                    </ul>
                </nav>
            </header>

    );
}

export default Header;
